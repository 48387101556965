import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="fh5co-header-section" className="sticky-banner">
			<div className="container">
				<div className="nav-header">
					<a href="#" className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></a>
					<a href="/"><img className="logo-header" src="/assets/images/logo-alt.png" alt="" /></a>
					<nav id="fh5co-menu-wrap" role="navigation">
						<ul className="sf-menu" id="fh5co-primary-menu">
							<li className={location.pathname === '/' ? 'active' : ''}><a className="color_dorado_oscuro" href="/" >Inicio</a></li>
							<li className={location.pathname === '/login' ? 'active' : ''}><a className="color_dorado_oscuro" href="/login">Login</a></li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
    )
}

export default Header;