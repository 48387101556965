import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../../Redux/actions/dashboard';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit'
    },
    btn_tabla: {
    	fontFamily: 'inherit',
    	letterSpacing: 'inherit',
    	fontSize: '16px'
    },
    contenido_indicador: {
    	color: '#fff'
    }
}

const Dashboard = () => {

	const dispatch = useDispatch();

	const dashboard = useSelector(state => state.dashboard);

	useEffect(() => {
        dispatch(getDashboard());
    }, [dispatch]);
    
    return (
    	<Fragment>
            <div className="jumbotron">
                <div className="container">
                    <h1 className="display-3" style={styles.titulo_seccion}>¡Hola Camilla!</h1>
                    <p>Desde este sistema podras gestionar tus invitados, mesas y post 🥰</p>
                    <p>
                        <a className="btn btn-primary btn-lg mt-2 mr-2" href="/invitaciones_listado" role="button" style={styles.btn_tabla}>Invitaciones</a>
                        <a className="btn btn-primary btn-lg mt-2 mr-2" href="/invitados_listado" role="button" style={styles.btn_tabla}>Invitados</a>
                        <a className="btn btn-primary btn-lg mt-2" href="/mesas_listado" role="button" style={styles.btn_tabla}>Mesas</a>
                    </p>
                </div>
            </div>
			<div className="container">
				<div className="row mb-3">
                    <div className="col-xl-4 col-lg-6">
                        <div className="card text-white bg-primary mb-3" >
                            <div className="card-header">Invitados</div>
                            <div className="card-body">
                                <p className="card-text display-1 text-center" style={styles.contenido_indicador}>{dashboard.invitados}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card text-white bg-success mb-3" >
                            <div className="card-header">Asistirán</div>
                            <div className="card-body">
                                <p className="card-text display-1 text-center" style={styles.contenido_indicador}>{dashboard.asistiran}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="card text-white bg-danger mb-3" >
                            <div className="card-header">No asistirán</div>
                            <div className="card-body">
                                <p className="card-text display-1 text-center" style={styles.contenido_indicador}>{dashboard.no_asistiran}</p>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
	    </Fragment>
    )
}

export default Dashboard;