import React from 'react';

import { useLocation } from 'react-router-dom';

const styles = {
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    }
}

const Image = () => {

	const location = useLocation();

    return (
        <div className="fh5co-hero" data-section="home">
			{/* <div className="fh5co-overlay"></div> */}
			<div className="duidui fh5co-cover text-center">
				<div className="display-t">
					<div className="display-tc">
						<div className="container">
							<div className="col-md-10 offset-md-1">
								<div className="animate-box">
									<h1 className="color_dorado_oscuro">{ location.pathname === '/' ? 'Mis XV años' : 'Camilla Silva' }</h1>
									<h2 className="color_dorado_oscuro">
										{
									        {
									          	'/': 'Camilla Silva',
										      	'/detalles': '¿Cuando y donde?',
										      	'/invitados': 'Invitados',
										      	'/regalos': 'Regalos',
										      	'/galeria': 'Galería',
										      	'/blog': 'Blog'
									        }[location.pathname] || 'Mis XV años'
							      		}
									</h2>
									{
										location.pathname === '/' ? <p><span>27.07.2024</span></p> : ''
									}
								</div>
								<p className="frase mt-5">Hay recuerdos que no voy a olvidar, personas y momentos que siempre voy a recordar. Porque eres parte de mi vida, es mi deseo compartir contigo este dia tan especial.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Image;